@use 'sass:math';

// Colors
@function color($color-name, $color-variant: 'base') {
    @return map-get(map-get($colors, $color-name), $color-variant);
}

// Add percentage of white to a color
@function tint($color, $percent) {
    @return mix(white, $color, $percent);
}

// Add percentage of black to a color
@function shade($color, $percent) {
    @return mix(black, $color, $percent);
}

// Font family and weights
@mixin font($font-family: base, $weight: null) {
    $family: map-get($fonts, $font-family);
    @if $weight {
        @if map-has-key($family, $weight) {
            $f-weight: map-get($family, $weight);

            @if (length($f-weight) > 1) {
                font-family: nth($f-weight, 2), map-get($family, fallback);
                font-weight: nth($f-weight, 1);
            } @else {
                font-family: map-get($family, font), map-get($family, fallback);
                font-weight: $f-weight;
            }
        } @else {
            font-family: map-get($family, font), map-get($family, fallback);
        }
    } @else {
        font-family: map-get($family, font), map-get($family, fallback);
    }
}
// Font-size clamp-builder
// Calculation from: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
@function clampBuilder($minFontSize, $maxFontSize) {
    // From viewport
    $minWidthPx: strip-unit(map-get($breakpoints, sm));
    // To viewport
    $maxWidthPx: strip-unit(map-get($breakpoints, xl));

    // Combine with base font size (px)
    $minWidth: math.div($minWidthPx, $font-size-base);
    $maxWidth: math.div($maxWidthPx, $font-size-base);

    // Create the scale between min and max
    $slope: math.div($maxFontSize - $minFontSize, $maxWidth - $minWidth);
    $yAxisIntersection: -$minWidth * $slope + $minFontSize;
    // Scss hack:
    $plus: '+';

    @return clamp(
        #{$minFontSize}rem,
        #{$yAxisIntersection}rem #{$plus} #{$slope * 100}vw,
        #{$maxFontSize}rem
    );
}

@mixin font-size($size: base, $skip-line-height: false) {
    $sizes: map-get($font-sizes, $size);

    @if map-has-key($sizes, base) and map-has-key($sizes, to) {
        $base: map-get($sizes, base);
        $to: map-get($sizes, to);
        $font-size: clampBuilder($base, $to);

        font-size: #{$base}rem;

        @supports (font-size: $font-size) {
            font-size: $font-size;
        }
    } @else {
        $base: map-get($sizes, base);
        font-size: #{$base}rem;
    }

    @if (map-has-key($sizes, line-height) and not $skip-line-height) {
        $line-height: map-get($sizes, line-height);
        line-height: $line-height;
    }
}

@function pxToRem($value) {
    @return math.div(strip-unit($value), strip-unit($font-size-base)) * 1rem;
}

// Input placeholder
@mixin input-placeholder {
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
    &::placeholder {
        @content;
    }
}

// Media queries
@mixin min-width($name) {
    @if not map-has-key($breakpoints, $name) {
        @media (min-width: $name) {
            @content;
        }
    } @else {
        @media (min-width: map-get($breakpoints, $name)) {
            @content;
        }
    }
}

@mixin max-width($name) {
    @if not map-has-key($breakpoints, $name) {
        @media (max-width: $name - 1) {
            @content;
        }
    } @else {
        @media (max-width: map-get($breakpoints, $name) - 1) {
            @content;
        }
    }
}

// Full-width
@mixin full-width {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

// No select
@mixin no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

    -webkit-user-drag: none;
    -moz-user-drag: none;
    user-drag: none;
}

// Screenreaders only
@mixin screenreaders-only($breakpoint: null) {
    @if ($breakpoint != null) {
        @include min-width($breakpoint) {
            position: absolute;
            overflow: hidden;
            width: 1px;
            height: 1px;
        }
    } @else {
        position: absolute;
        overflow: hidden;
        width: 1px;
        height: 1px;
    }
}

// Hyphens: use with care...
@mixin hyphens {
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

@mixin text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

@mixin container($size: base) {
    width: 100%;
    max-width: map-get($container-width, $size);
    margin-left: auto;
    margin-right: auto;
    padding-left: $margin;
    padding-right: $margin;
}

// Text aligning because of font...
@function text-align($space-y, $space-x: false, $offset: -0.1) {
    @if ($space-x) {
        @return #{$space-y * (1 - $offset)} $space-x #{$space-y * (1 + $offset)};
    }

    @return #{$space-y * (1 - $offset)} $space-y #{$space-y * (1 + $offset)};
}

// Strip units from number
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return math.div($number, $number * 0 + 1);
    }

    @return $number;
}

@mixin uppercase {
    @include font(base, normal);
    text-transform: uppercase;
}

/*********************************/
/*          ANIMATIONS           */
/*********************************/

// Animation delay for childs
@mixin animation-delay($count, $delay, $initial: 0, $start: 1, $child: false) {
    @if ($child) {
        #{$child} {
            animation-delay: (($count + 1) * $delay) + $initial;
        }

        @for $i from $start through $count {
            &:nth-child(#{$i}) #{$child} {
                animation-delay: ($i * $delay) + $initial;
            }
        }
    } @else {
        animation-delay: (($count + 1) * $delay) + $initial;

        @for $i from $start through $count {
            &:nth-child(#{$i}) {
                animation-delay: ($i * $delay) + $initial;
            }
        }
    }
}

// Animation delay for childs, reversed
@mixin animation-delay-reversed($count, $delay, $initial: 0, $start: 1, $child: false) {
    @if ($child) {
        #{$child} {
            animation-delay: (($count + 1) * $delay) + $initial;
        }

        @for $i from $start through $count {
            &:nth-last-child(#{$i}) #{$child} {
                animation-delay: ($i * $delay) + $initial;
            }
        }
    } @else {
        animation-delay: (($count + 1) * $delay) + $initial;

        @for $i from $start through $count {
            &:nth-last-child(#{$i}) {
                animation-delay: ($i * $delay) + $initial;
            }
        }
    }
}

// Transition delay for childs
@mixin transition-delay($count, $delay, $initial: 0, $start: 1, $child: false) {
    @if ($child) {
        #{$child} {
            transition-delay: (($count + 1) * $delay) + $initial;
        }

        @for $i from $start through $count {
            &:nth-child(#{$i}) #{$child} {
                transition-delay: ($i * $delay) + $initial;
            }
        }
    } @else {
        transition-delay: (($count + 1) * $delay) + $initial;

        @for $i from $start through $count {
            &:nth-child(#{$i}) {
                transition-delay: ($i * $delay) + $initial;
            }
        }
    }
}

// Enter and exit animation helpers
@mixin page-enter {
    .page--entering & {
        @content;
    }
}

@mixin page-exit {
    .page--exiting & {
        @content;
    }
}

@mixin entering($is-wrapper: false) {
    @if ($is-wrapper) {
        &[data-animation='entering'] {
            @content;
        }
    } @else {
        [data-animation='entering'] & {
            @content;
        }
    }
}

@mixin exiting($is-wrapper: false) {
    @if ($is-wrapper) {
        &[data-animation='exiting'] {
            @content;
        }
    } @else {
        [data-animation='exiting'] & {
            @content;
        }
    }
}

// links
@mixin underline($dark: false) {
    $offset: 0.1em;
    $thickness: 1px;
    $color: rgba(black, 0.15);

    @if ($dark) {
        $color: currentColor;
    }
    // $offset: 5px;
    text-decoration: none;

    @supports (text-underline-offset: 0.24em) {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 0.16em;
        text-decoration-skip-ink: none;
        text-decoration-skip: none;
        text-decoration-color: $color;
    }

    @supports not (text-underline-offset: 0.24em) {
        display: inline;
        background-size: #{$thickness} 1em;
        box-shadow:
            inset -1px calc(-#{$offset} + #{$thickness}) $color-background,
            inset 0 -#{$offset} $color;
    }
}

// Cover
@mixin cover($position: absolute) {
    position: $position;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Overflow stuff
@mixin overflow-scroll-y {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin overflow-scroll-x {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin line-clamp($lines) {
    line-clamp: $lines;

    @supports (display: -webkit-box) {
        display: -webkit-box;
        overflow: hidden;
        padding-top: 0.1em; // Fix for special letters
        padding-bottom: 0.1em; // Fix for special letters
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
    }
}
