@import 'styles/_helpers';

.promoted-lessons {
    $this: &;
    margin-bottom: $section-spacing--medium;
    padding: $spacing;
    border-radius: $border-radius--large;
    background-color: color(primary, xlight);
    animation: fade-in 500ms $ease-in-out-quint--custom both;

    &__title {
        @include font(base, bold);
        @include font-size(title);
        grid-area: title;
        margin: 0 0 0.1em;
    }

    &__preamble {
        grid-area: preamble;
        font-size: inherit;
    }

    &__list {
        display: grid;
        grid-gap: $gutter;
        grid-area: list;
    }

    &__content {
        @include font-size(sub-title);
        grid-area: content;
    }

    &__more {
        display: flex;
        justify-content: center;
        margin-top: $gutter;
    }

    &--single {
        @include min-width(lg) {
            display: grid;
            align-items: start;
            grid-gap: $gutter;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'content list';
            padding: $gutter;
            border-radius: $border-radius--xlarge;
        }

        @include min-width(xxl) {
            padding-left: calc(#{$gutter} * 2);
            grid-template-columns: auto 360px;
            grid-template-areas: 'content list';
        }

        #{$this}__content {
            @include font-size(base);
            align-self: center;
            max-width: 24rem;
            margin: 0;

            @include min-width(sm) {
                @include font-size(sub-title);
            }
        }

        #{$this}__title {
            margin: 0 0 0.55em;

            @include max-width(sm) {
                @include font-size(sub-title);
                @include hyphens;
            }
        }

        @include max-width(lg) {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: $spacing--large;

            #{$this}__list {
                max-width: 13rem;
                margin: 0 auto $spacing--large;
            }

            #{$this}__title {
                @include max-width(md) {
                    margin-bottom: $spacing;
                }
            }

            #{$this}__content {
                order: 2;
            }
        }
    }

    &--three,
    &--double {
        &:not(:last-child) {
            margin-bottom: $section-spacing--medium;
        }

        #{$this}__content {
            text-align: center;
            margin-bottom: $section-spacing--small;
        }

        #{$this}__title {
            @include font-size(title-medium);
            text-wrap: balance;
            max-width: 30rem;
            margin: 0 auto 1rem;
        }

        #{$this}__preamble {
            text-wrap: balance;
            max-width: 30rem;
            margin: 0 auto;
        }

        #{$this}__list {
            @include min-width(md) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include min-width(1500px) {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    &--double {
        #{$this}__list {
            @include min-width(md) {
                grid-template-columns: repeat(2, 1fr);
                max-width: 45rem;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}
