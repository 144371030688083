$colors: (
    primary: (
        dark: var(--color-dark),
        base: var(--color-promoted),
        light: var(--color-background),
        xlight: var(--color-light),
    ),
    neutral: (
        xlight: #f8f6f2,
        light: #f2eee6,
        base: #e4dfd7,
        dark: #dcd8cf,
        xdark: #3d3b38,
    ),
    scrollytelling: (
        primary: #c53d76,
        text: #4ef3ff,
        text2: #ac4743,
        background1: #141d34,
        background2: #0f1626,
        positive: #6ad576,
        negative: #a93132,
    ),
);

$color-light: #fff;
$color-dark: #000000;

// Specific colors.
// Variables are there to be reused, so being  to specific or creating to many will create the opposite situation
$color-background: $color-light;

$color-text: $color-dark;
$color-muted: rgba($color-text, 0.5);

$color-links: $color-text;
$color-links--hover: $color-text;

$color-outline: rgba(#99d6ea, 0.6);
$color-backdrop: color(primary, light);

$color-warning: #f4d5ce;
$color-warning--dark: #f0c3b9;
$color-success: #c2eade;
$color-success--dark: #b1e0cc;
