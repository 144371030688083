@import 'styles/_helpers';

.definition-list {
    $this: &;
    margin-top: $spacing--small;
    margin-bottom: $section-spacing--medium;

    @include min-width(lg) {
        @include font-size(sub-title);
    }

    &__list {
        display: grid;
        grid-gap: $gutter;
        grid-row-gap: $spacing--large;
        grid-template-columns: repeat(2, 1fr);

        @include min-width(md) {
            grid-gap: $gutter;
        }

        @include min-width(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
        margin-bottom: $spacing;
    }

    &__letter {
        @include font-size(title);
        position: sticky;
        z-index: 2;
        top: 0;
        padding: 0.1em 0;
        background-color: $color-background;
        margin: 0 0 $spacing--small;

        animation: fade-in 500ms $ease-in-out-quint--custom both;
    }

    &__group {
        margin-bottom: $section-spacing--medium;
    }

    &__item {
        animation: entry-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 50ms);
    }

    &__type {
        @include font(base, bold);
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}
