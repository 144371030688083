@import 'styles/_helpers';

.topic-entry {
    $this: &;
    $easing-enter: 600ms $ease-out-quart;
    $easing-exit: 600ms $ease-in-quart;

    &__main-title {
        @include font-size(title);
        margin-bottom: $spacing;
        animation: fade-in 500ms $ease-in-out-quint--custom both;
    }

    &__content {
        display: grid;
        grid-gap: $spacing--large $gutter;
        grid-template-columns: repeat(2, 1fr);

        @include min-width(xxl) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        @include font-size(base);
        position: relative;
        display: block;
        height: 100%;
        text-align: center;

        animation: entry-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 60ms);

        @include min-width(md) {
            @include font-size(sub-title);
        }

        &:hover,
        &:focus {
            #{$this}__illustration {
                background-color: color(primary);
            }

            #{$this}__count {
                background-color: color(primary, light);
            }
        }
    }

    &__illustration {
        position: relative;
        margin-bottom: $spacing--small;
        border-radius: $border-radius;
        aspect-ratio: 1/1;
        background-color: color(primary, light);

        @include min-width(lg) {
            border-radius: $border-radius--large;
        }
    }

    &__image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__title {
        @include font(base, normal);
        margin-top: auto;
        font-size: inherit;
    }

    &__count {
        @include font-size(small);
        position: absolute;
        top: $spacing--small;
        right: $spacing--small;
        padding: 4px 12px 3px;
        border-radius: $border-radius--small;
        background-color: color(primary);
    }

    &__footer {
        margin-top: $spacing;
    }

    &--frontpage {
        &:not(:last-child) {
            margin-bottom: $section-spacing--medium;
        }
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}
