@import 'styles/_helpers';

.hero {
    $this: &;
    --padding: 1.4rem;

    @include min-width(lg) {
        --padding: #{$gutter};
    }

    @include min-width(xxl) {
        --padding: calc(#{$gutter} * 2);
    }

    position: relative;
    display: grid;
    width: 100%;

    overflow: hidden;
    border-radius: $border-radius--large;
    background-color: color(primary, xlight);

    @include min-width(md) {
        grid-gap: $gutter;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'content image';
    }

    @include min-width(lg) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 'content content image';
        border-radius: $border-radius--xlarge;
    }

    &:hover,
    &:focus:focus-visible {
        background-color: color(primary);
    }

    &:not(:last-child) {
        margin-bottom: $section-spacing--medium;
    }

    &__content {
        padding: var(--padding);
        text-align: center;

        @include min-width(md) {
            padding-right: 0;
            text-align: left;
            grid-area: content;
        }
    }

    &__image-wrapper {
        position: relative;

        @include min-width(md) {
            grid-area: image;
        }
    }

    &__image {
        max-width: 60%;
        margin: $spacing auto -0.4rem;

        @include min-width(md) {
            max-width: 100%;
            margin: 0;

            @include cover;
            object-fit: contain;
        }
    }

    &__title {
        @include font(base, bold);
        @include font-size(sub-title);
        margin: 0 0 $spacing--small;

        @include min-width(lg) {
            @include font-size(title);
        }
    }

    &__preamble {
        @include font-size(base);
        text-wrap: balance;

        @include min-width(lg) {
            @include font-size(sub-title);
        }
    }

    &--image {
        #{$this}__image {
            max-width: 100%;
            margin: 0;
        }

        @include min-width(md) {
            padding-right: $gutter;
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}
