@import 'styles/_helpers';

.cover {
    $this: &;

    display: grid;
    grid-gap: $gutter;

    @include min-width(lg) {
        grid-template-columns: auto 16rem;
    }

    @include min-width(xl) {
        grid-template-columns: auto 20rem;
    }

    &__title {
        @include font-size(title-medium);
        text-wrap: balance;
        margin-bottom: 2.5rem;
    }

    &__illustration {
        order: -1;
        justify-self: center;
        width: 16rem;
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
        transition: clip-path 660ms $ease-in-out-quint--custom;

        @include min-width(lg) {
            order: 2;
            justify-self: auto;
            width: 100%;
        }

        @include min-width(xxl) {
            margin-top: -3rem;
        }

        &--collapse {
            @include min-width(lg) {
                clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
            }

            //         from {
            // }

            // to {
            //     clip-path: inset(0 0 0 0 round #{$border-radius--small});
            // }
        }
    }
}
