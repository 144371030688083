$breakpoints: (
    xs: 410px,
    sm: 620px,
    md: 760px,
    lg: 1080px,
    xl: 1280px,
    xxl: 1400px,
);

$height-breakpoint: 800px;

// Grids max-width
$container-width: (
    base: 84rem,
    tight: 44rem,
);

// Spacing
$spacing: var(--spacing);
$spacing--xsmall: 10px; // TODO: Use css-variable for this also?
$spacing--small: var(--spacing--small);
$spacing--large: var(--spacing--large);

// Margins is used only for the page margins
$margin--small: $spacing;
$margin: var(--margin);
$gutter: var(--gutter);
$indention: 1.4em;
$section-spacing: var(--section-spacing);
$section-spacing--medium: var(--section-spacing-medium);
$section-spacing--small: var(--section-spacing--small);

// Heights
$header-height: var(--header-height);

// Borders and strokes
$border-width: 1px;
$border-radius--xsmall: 4px;
$border-radius--small: 12px;
$border-radius: 21px;
$border-radius--large: 30px;
$border-radius--xlarge: 48px;
$border-style: $border-width solid color(neutral, light);

$shadow: 0 0 44px rgba(black, 0.05), 0 0 3px rgba(black, 0.01);

// Z-index levels
$level-1: 100;
$level-2: 200;
$level-3: 300;
$level-4: 400;

// Icon strokes
$icon-stroke: 1.8;

// Better easings
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);

$ease-in-out-quint--custom: cubic-bezier(0.86, 0, 0.4, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);
$ease-in-quint: cubic-bezier(0.64, 0, 0.78, 0);

$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);

$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-out-quad: cubic-bezier(0.45, 0, 0.55, 1);

$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-out-back--subtle: cubic-bezier(0.175, 0.885, 0.32, 1.17);

// Muted sections
$muted-opacity: 0.1;
$muted-transition: opacity 500ms $ease-out-quart;
