@import 'styles/_helpers';

.dropdown {
    $this: &;
    $gap: 4px;
    max-width: 22rem;
    min-width: 2rem;
    margin-bottom: $spacing--xsmall;

    @include min-width(md) {
        max-width: 22rem;
        min-width: 4rem;
    }

    &--spaceless {
        margin-bottom: 0;
    }

    &__trigger {
        @include font-size(small);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        color: $color-text;
        border-radius: $border-radius;
        padding: 15px 23px;
        background-color: color(primary);
        width: max-content;
        &:hover,
        &:focus {
            background-color: color(primary, dark);
        }

        &--expanded {
            z-index: $level-3 + 1;
            transition: color 300ms $ease-in-out-quint--custom;
        }

        &--small {
            @include font-size(small);
            padding: 15px 15px;

            @include min-width(md) {
                padding: 18px 23px;
                @include font-size(base);
            }
        }

        &--subtle {
            background-color: color(neutral, light);

            &:hover,
            &:focus {
                background-color: color(neutral, dark);
            }
        }

        &--inline {
            font: inherit;
            padding: 0;
            margin: 0;

            &,
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        &--selected {
            background-color: color(neutral);
        }
    }

    &__animation {
        position: relative;

        &--expanded {
            z-index: $level-3;

            @include max-width(md) {
                z-index: $level-4;
                display: grid;
                align-items: center;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: $margin;
            }
        }
    }

    &__content {
        color: $color-text;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $gap;

        max-height: calc(100vh - 120px);
        @include overflow-scroll-y;
        overscroll-behavior: contain;

        @include min-width(md) {
            max-height: calc(90vh - 200px);
            align-items: flex-start;
        }
    }

    &__wrapper {
        position: relative;
        width: 100%;
        outline: 0;
        z-index: 2;
        margin-top: $gap;

        @include min-width(md) {
            position: absolute;
            top: 0;
            left: 0;
            width: auto;
            max-width: var(--max-width);
            min-width: min(80vw, var(--min-width));
        }
        &--right {
            @include min-width(md) {
                //left: auto;
                right: 0;
            }
        }
    }

    &__title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        color: $color-dark;
        z-index: $level-4;
        padding: $margin $spacing $spacing--small $spacing--small;

        @include min-width(md) {
            display: none;
        }

        &--subtle {
            background-color: color(neutral, light);
        }

        @include entering {
            animation: item-enter 600ms $ease-in-out-quint--custom both;
        }

        @include exiting {
            animation: item-exit 640ms $ease-in-out-quint--custom both;
        }
    }

    &__option {
        @include font-size(small);
        text-align: left;
        color: $color-text;
        border-radius: $border-radius;
        white-space: nowrap;
        padding: 15px 23px;
        background-color: color(primary);

        &:hover,
        &:focus:focus-visible {
            background-color: color(primary, dark);
        }

        &--selected {
            box-shadow: inset 0 0 0 4px rgba(black, 0.15);
        }

        &--subtle {
            background-color: color(neutral, light);
            &:hover,
            &:focus:focus-visible {
                background-color: color(neutral, dark);
            }
        }

        @include entering {
            animation: item-enter 400ms $ease-out-cubic both;
            @include animation-delay(10, 35ms);
        }

        @include exiting {
            animation: item-exit 300ms $ease-in-cubic both;
            @include animation-delay(10, 30ms);
        }
    }

    &__close {
        position: absolute;
        top: $margin;
        right: $margin;
        color: $color-dark;
        z-index: $level-4;

        @include min-width(md) {
            display: none;
        }
    }

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-light;
        outline: none;
        opacity: 0.9;

        @include entering {
            animation: fade-in 300ms $ease-out-cubic both;
        }

        @include exiting {
            animation: fade-out 500ms $ease-in-cubic forwards;
        }

        &--prevent-close {
            cursor: default;
        }
    }

    &--overflow {
        #{$this}__option {
            padding: 8px 23px;
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes fade-out {
    to {
        opacity: 0;
    }
}

@keyframes item-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius});
    }
}

@keyframes item-exit {
    from {
        clip-path: inset(0 0 0 0 round #{$border-radius});
    }

    to {
        clip-path: inset(100% 100% 0 0 round #{$border-radius});
    }
}
