@import 'styles/_helpers';

.search {
    $this: &;
    --padding: 18px 50px 18px 25px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    align-items: flex-start;
    width: 100%;
    margin-bottom: $section-spacing--small;

    &__wrapper {
        position: relative;
        flex-grow: 2;
        align-self: flex-start;

        @include min-width(lg) {
            max-width: 20rem;
            min-width: 16rem;
        }
    }

    &__input {
        @include font(base, normal);
        @include font-size(base);
        padding: var(--padding);
        width: 100%;
        border-radius: $border-radius;
        background-color: color(neutral, light);

        &:hover,
        &:focus {
            border-color: color(primary);
        }

        @include input-placeholder {
            color: $color-muted;
        }
    }

    &__submit {
        position: absolute;
        display: grid;
        place-content: center;
        top: 0;
        right: 0;
        height: 100%;
        width: 3rem;
    }

    &__filters {
        display: flex;
        align-self: center;
        gap: 6px;
    }

    &__input:not(:placeholder-shown) + #{$this}__placeholders {
        display: none;
    }

    &__placeholders {
        pointer-events: none;
        position: absolute;
        display: flex;
        gap: 0.2em;
        top: 0;
        left: 0;
        color: $color-muted;
        padding: var(--padding);
    }

    &__suggestions {
        position: relative;
    }

    &__suggestion {
        position: absolute;
        clip-path: inset(0 100% 0 0);
        transition: clip-path 500ms $ease-in-quart;

        &--active {
            transition: clip-path 550ms $ease-out-quart;
            clip-path: inset(0 0% 0 0);
            transition-delay: 580ms;
        }
    }

    &--loading {
        #{$this}__wrapper {
            &:before {
                pointer-events: none;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: $border-radius;
                mix-blend-mode: darken;
                background-color: color(neutral);
                animation: loading 800ms $ease-out-cubic infinite;
            }
        }
    }
}

@keyframes word-mask {
    0% {
        clip-path: inset(0 100% 0 0);
    }

    50% {
        clip-path: inset(0 0 0 0);
    }

    100% {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes loading {
    from {
        clip-path: inset(0 100% 0 0);
    }

    to {
        clip-path: inset(0 0 0 0);
    }
}
